/*
 Include in global.css
 */
#searchbar {
    width: 420px;
    padding: 0.45rem 0.5rem;
    @apply absolute top-0 z-[100];
}

@media (max-width: 500px) {
    #searchbar {
        width: 100vw;
    }
}

#searchbar .wrapper {
    @apply bg-white bg-opacity-75 backdrop-blur font-godus rounded-md shadow absolute p-1;
}


#searchbar .clear-icon {
    @apply m-0 mr-2;
}

#searchbar div.line {
    @apply border-gray-300 mx-2;
}

#searchbar .wrapper ul {
    @apply pb-0.5;
}

#searchbar .wrapper li.selected {
    @apply bg-white rounded-md;
}