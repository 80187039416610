@import "@/components/search/Searchbar.css";
@import "ol/ol.css";
@import "_map.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    --bg-color: #c6ad8f;
    --bg-color-light: #d5cabc;
    background-color: var(--bg-color);
    background-image: url("../public/images/map-bg.jpg");

    @apply flex w-full h-full font-godus;
}

#__next {
    @apply flex flex-1 justify-center items-center;
}