:root {
    --map-water-color: #2162af;
    --map-river-color: var(--map-water-color);
    --map-sea-color: var(--map-water-color);
    --map-path-color: #2a1508;
    --map-main-path-color: darkred;
    --control-gap: 1rem;
    --control-padding: .75rem;
}

#Landwege use, #Wasserwege use {
    pointer-events: all;
    stroke-width: 40;
    cursor: pointer;
}

#Landwege path, #Wasserwege path {
    stroke-width: 0.6;
}

#Wasserwege {
    color: var(--map-water-color);
}

#Wasserwege text {
    fill: currentColor;
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.84));
}

#Wasserwege path {
    stroke-dasharray: 4 2;
    stroke-width: 1;
    stroke: currentColor;
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.84));
}

#Seen {
    color: var(--map-sea-color);
}

#Seen path {
    stroke-dasharray: 1 1;
    stroke-width: 1;
    stroke: currentColor;
}

#Grenzen path {
    stroke-dasharray: 6 3;
    stroke-width: 2;
    stroke: #000;
}

#Landwege path {
    filter: drop-shadow(0 0 0.5px #fff);
    stroke: var(--map-path-color);
}

#Landwege #Nebenrouten path {
    stroke: var(--map-path-color);
    stroke-dasharray: 1.5;
    stroke-width: 0.5;
}

#Landwege path[id^="hw"] {
    stroke: var(--map-main-path-color);
}

#Label g[id^="Reich"] text, #Label g[id^="Reich"] path {
    position: relative;
    fill: #fff;
}

#Label g[id^="Hauptstadt"] text, #Label g[id^="Hauptstadt"] path {
    fill: #4B3120;
}

#Label g[id^="Stadt"] text, #Label g[id^="Stadt"] path {
    fill: #4B3120;
}

#Label g[id^="Ort"] > g {
    position: relative;
}

#Label g[id^="Ort"] text, #Label g[id^="Ort"] path {
    fill: #000;
}

#Label g[id^="Landmarke"] text, #Label g[id^="Landmarke"] path {
    fill: white;
    stroke: #000;
    stroke-width: 0.2;
}

.ol-viewport .ol-layer:has(> canvas) {
    pointer-events: none;
}

.ol-viewport canvas {
    pointer-events: none;
}

.ol-zoom {
    inset: auto 0.75em calc(25px + var(--control-padding) + var(--control-gap)) auto;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(0.5rem);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 0.25rem;
}

.ol-zoom button:first-of-type {
    padding-bottom: 0.2rem;
}

.ol-zoom button:last-of-type {
    padding-bottom: 0.2rem;
}

@media (max-width: 500px) {
    .ol-zoom {
        display: none;
    }
}

.ol-scale-bar {
    --ol-foreground-color: #000;
    --ol-subtle-foreground-color: #000;
    background: none;
    inset: auto 0.75em 0.75em auto;
}

@media (max-width: 500px) {
    .ol-scale-bar {
        display: none;
    }
}